import Logo from '@theme/components/molecule/Logo'
import MainMenu from '~/themes/base/components/shop/MainMenu'
import MainMenuDropdownContent from '@theme/components/shop/MainMenuDropdownContent'

export default {
  extends: MainMenu,
  components: {
    Logo,
    MainMenuDropdownContent,
  },
  data() {
    return {
      innerContainerClass: '',
    }
  },
}
