import AppImage from '@theme/components/atom/AppImage'
import MainMenuDropdownContent from '~/themes/base/components/shop/MainMenuDropdownContent'

export default {
  extends: MainMenuDropdownContent,
  props: {
    defaultColumn: {
      type: Boolean,
      default: false,
    },
    defaultGrid: {
      type: Boolean,
      default: false,
    },
    defaultLevel: {
      type: Number,
      default: 1,
    },
    parentItem: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      menuImage: false,
      defaultImage: false,
    }
  },
  components: {
    AppImage,
    MainMenuDropdownContent: () => import('@theme/components/shop/MainMenuDropdownContent'),
  },
  created() {
    if (this.defaultLevel == 1) {
      const { image } = this.parentItem
      if (image) {
        this.defaultImage = image
      }
    }
  },
  methods: {
    setMenuImage(item) {
      if (this.defaultLevel > 1) {
        this.$emit('setMenuImage', item)
      } else {
        const { image } = item || {}
        if (this.menuImage !== image) {
          this.menuImage = image || false
        }
      }
    },
  },
}
